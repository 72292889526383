@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
button:focus {
  outline: none !important;
}

html,
body {
  margin: 0px !important;
}

html,
body,
div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  font-family: 'Late', sans-serif !important;
}

button,
input,
select {
  font-family: 'Late', sans-serif !important;
}

* {
  box-sizing: border-box;
}

.Mui-selected {
  background: rgba(0, 147, 245, 0.08) !important;
}

.btn-primary {
  background-color: #0063cc !important;
  border-color: #0063cc !important;
}

.btn-outline-primary {
  border-color: #0063cc !important;
  color: #0063cc !important;
}

.btn-outline-primary:hover {
  background-color: #0063cc !important;
  color: #fff !important;
}

.btn-primary:disabled,
.btn-outline-primary:disabled {
  opacity: 0.8 !important;
}

div#modal-anticipation-send-xml {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: auto;
  max-height: 500px;
  background-color: #fff;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  padding: 40px;
}

div#modal-anticipation-send-xml label#label-xml-file {
  padding: 15px;
  border-radius: 5px 0px;
  border: 1px dashed #3757a1;
  width: 100%;
  font-weight: 900;
  color: #3757a1;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  transition: 300ms;
}

div#modal-anticipation-send-xml label#label-xml-file small {
  display: block;
  font-size: 11px;
}

div#modal-anticipation-send-xml label#label-xml-file small:hover {
  background: #f7f7f7;
}

div#modal-anticipation-send-xml input#xml-file {
  display: none;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 30px 4px 30px 6px;
  padding: 15px;
  background: #fff;
  color: #999;
  border-right: 20px solid #3757a1 !important;
  min-height: 70px;
  text-align: center;
  font-weight: 900;
}

.Toastify__toast--dark {
  border-color: #222;
  color: #222;
}

.Toastify__toast--default {
  border-color: #c9c9c9;
  color: #c9c9c9;
}

.Toastify__toast--info {
  border-color: #0178ff;
  color: #0178ff;
}

.Toastify__toast--success {
  border-color: #2bc76c;
  color: #2bc76c;
}

.Toastify__toast--warning {
  border-color: #3757a1;
  color: #3757a1;
}

.Toastify__toast--error {
  border-color: #d41010;
  color: #d41010;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  color: #999;
}

.Toastify__close-button--default {
  color: #999;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  background: #3757a1;
}

.border-1 {
  border: 1px solid transparent;
}

.radius-4 {
  border-radius: 0.2rem;
}

.radius-6 {
  border-radius: 0.6rem;
}

.bg-light-green {
  background: #f6fffa;
  color: #284233;
  border: 1px solid rgba(40, 66, 51, 0.13);
}

.bg-light-yellow {
  background: #fffdf9;
  color: #423228;
  border: 1px solid rgba(40, 66, 51, 0.13);
}

.bg-gray {
  background: rgba(0, 0, 0, .05);
}

.text-bfcdigital {
  color: #3757a1;
}

.card-body p {
  font-size: 14px;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

table.table-bfc thead tr th {
  font-size: 0.7rem !important;
}

table.table-bfc tbody tr td {
  font-size: 0.7rem !important;
}

table.table-bfc thead tr th div.arrows-order {
  margin-left: 4px;
}

table.table-bfc thead tr th div.arrows-order button {
  padding: 0;
}

table.table-bfc thead tr {
  white-space: nowrap;
  background: rgba(55, 87, 161, 0.2);
}

table tbody tr td,
table thead tr th {
  vertical-align: middle !important;
}

.table-sm {
  font-size: 0.8rem;
}

@-webkit-keyframes loadBg {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }
}

@keyframes loadBg {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }
}

.bg-load {
  background-color: rgba(0, 0, 0, .15);
  -webkit-animation: loadBg 750ms linear infinite;
          animation: loadBg 750ms linear infinite;
  width: 100%;
  border-radius: 4px;
}

.min-height-100vh {
  min-height: 100vh;
}

.bg-main {
  background: #3757a1;
}

.text-main {
  color: #3757a1;
}

.input-date-styled {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #999;
}

.white-space-no-wrap {
  white-space: nowrap !important;
}

button:disabled {
  cursor: not-allowed !important;
}

input[type='checkbox'] {
  width: 1.2rem;
  height: 1.2rem;
}

.white-space-nowrap {
  white-space: nowrap;
}

table.table-fixed-top thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
}

@media screen and (max-width: 1024px) {
  table.table-bfc thead tr th {
    font-size: 0.6rem !important;
  }
  table.table-bfc tbody tr td {
    font-size: 0.6rem !important;
  }
}
